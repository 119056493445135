import React from 'react'
import BaseLayout from '@layouts/base'
import { Link } from 'gatsby'

const OpportunitiesPage = (): JSX.Element => {
  return (
    <BaseLayout pageIsResponsive title='Opportunities'>
      <section className='page-content'>
        <div
          className='section-bottom-shadow'
          style={{
            backgroundImage:
              'linear-gradient(0deg, rgba(130, 215, 247, 0.3) 0%, rgba(130, 215, 247, 0.1) 100%)',
            borderColor: '#61d9fa',
            overflow: 'hidden',
          }}
        >
          <div className='container'>
            <h1 className='page-content__title'>Opportunities</h1>
          </div>
        </div>
        <div className='page-content__container container'>
          <div className='sidebar-layout' style={{ marginTop: '3em' }}>
            <div className='sidebar-layout__main text-content'>
              <h2>Software Developer: Public Website &amp; Internal Tools</h2>
              <strong>About the Company</strong>
              <p>
                {`FullCalendar LLC develops and sells the internet’s most widely-used web-based calendar software. Our product is a front-end developer library with over `}
                <em>1 million</em>
                {` downloads per month. It's released under a hybrid open-source / premium license. You’ll be joining a small agile team that values pragmatism and flexibility over process and fixed schedules.`}
              </p>
              <strong>About the Role</strong>
              <p>
                {`You’ll take sole responsibility over the following vital parts of the business:`}
              </p>
              <ul>
                <li>
                  <strong>Public Website</strong>
                  {` - attracts customers, educates them on the product`}
                </li>
                <li>
                  <strong>Internal Tools</strong>
                  {` - makes our managers and support reps more productive`}
                </li>
              </ul>
              <p>
                {`We are looking for a skilled front-end developer. This role is different from many other developer roles in the following ways:`}
              </p>
              <ul>
                <li>
                  <strong>Projects will be well-defined</strong>
                  {` and requirements won’t change drastically. Your “PM” will be a seasoned developer who understands and respects the development process.`}
                </li>
                <li>
                  <strong>Your graphic design skills will matter.</strong>
                  {` While each project’s functionality will be well-defined, many aspects of the graphic design will be improvised by you, especially when it comes to responsive layouts.`}
                </li>
                <li>
                  <strong>You’ll work with fresh codebases.</strong>
                  {` No more wading through massive amounts of legacy code. However, you will be rewriting and porting over content from other codebases.`}
                </li>
                <li>
                  <strong>You’ll be mentored</strong>
                  {` by some pretty influential open source developers who maintain the core FullCalendar project.`}
                </li>
              </ul>
              <strong>Projects</strong>
              <p>You'll be working on the following [fun] projects:</p>
              <ul>
                <li>
                  <strong>Internal Admin UI</strong>
                  {` for tasks related to customers, licenses, and renewals. Heavily used by sales/support. Will be accessed as a standalone web page as well as a sized-down iframe embedded within our CRM and support systems. Rewrite/redesign of an existing system.`}
                </li>
                <li>
                  <strong>Issue Tracker Visualizer</strong>
                  {` for sorting, grouping, and displaying stats for feature and bug tickets. Used internally for task prioritization. Also visible on the public website. A rewrite/redesign of an `}
                  <Link to='/issues'>existing system</Link>.
                </li>
                <li>
                  <strong>
                    {`Moving the entire public website to `}
                    <a href='https://v2.docusaurus.io/'>Docusaurus</a>
                  </strong>
                  {` or other React-based documentation-friendly static-ish website framework. The tedious process of porting over individual docs will be done by the core developers.`}
                </li>
                <li>
                  <strong>System for runnable code samples.</strong>
                  {` For displaying JS/React/Vue/Angular code samples in each article of the docs that can be opened up in a code playground like Codepen.`}
                </li>
                <li>
                  <strong>A supercharged demos page.</strong>
                  {` We intend to wow customers with a large library of interactive demos. This will likely be based on whatever system you devise in the previous
                  project. Overhaul of an `}
                  <a href='/demos'>existing page</a>.
                </li>
                <li>
                  <strong>Writing individual code samples</strong>
                  {` for FullCalendar's docs. This ongoing task may seem a bit tedious, but it will teach you about our core product’s API.`}
                </li>
              </ul>
              <strong>Location, Hours, and Compensation</strong>
              <p>You’ll be a full-time remote contractor working flexible hours.</p>
              <p>Pay is between $35 - $40 / hour, depending on experience and location.</p>
              <strong>Experience</strong>
              <p>You'll need existing experience in the following areas:</p>
              <ul>
                <li>JavaScript (highly proficient)</li>
                <li>React (highly proficient)</li>
                <li>HTML/CSS (highly proficient)</li>
                <li>Responsive design techniques</li>
                <li>Static site generators (like Next.js) and JAM stack</li>
                <li>Build systems (like Webpack)</li>
                <li>Git</li>
                <li>Bash scripting and sysadmin basics</li>
              </ul>
            </div>
            <div className='sidebar-layout__sidebar'>
              <div className='sidebar-card' style={{ marginTop: 0 }}>
                <p>Interested in this position?</p>
                <a
                  href='https://www.indeed.com/job/developer-public-website-internal-tools-9b377205f84fe222'
                  className='button button--filled button--blue'
                >
                  Apply &raquo;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export default OpportunitiesPage
